const API_KEY = 'ff34e17681f84783beff0fa34fbf196c';
const PAGE_SIZE = '100';
const NEWS_API_BASE_URL = 'https://nomoreparties.co/news/v2/everything?';
// const NEWS_API_BASE_URL = 'https://newsapi.org/v2/everything?';

const NKVASOV_API_BASE_URL = 'https://api.news-explorer.nkvasov.ru';
// const NKVASOV_API_BASE_URL = 'http://217.25.93.59';
// const NKVASOV_API_BASE_URL = 'https://api.nkvasov.students.nomoreparties.space';

export { API_KEY, NEWS_API_BASE_URL, PAGE_SIZE, NKVASOV_API_BASE_URL }
