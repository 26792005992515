import React from 'react';
import './About.css';
import author from '../../images/anteater.jpg';

const About = (props) => {
  return (
    <section className="about">
      <img src={author} className="about__image" alt="фото автора" />
      <div className="about__text-container">
        <h2 className="about__title">О проекте</h2>
        <p className="about__text">
          Этот проект создан в качестве дипломного по программе обучения на
          веб-разработчика в Яндекс-Практикуме.
        </p>
        <p className="about__text">
          В приложении реализован функционал для регистрации и авторизации
          пользователя. Включена возможность сохранения новостей в "Избранное".
          Проект включает в себя фронтенд- и бэкенд-части.
        </p>
        <p className="about__text">
          Для поиска новостей используется NewsAPI, для работы с данными
          ползьзователя создан свой NewsExplorerAPI. В основе бэкенд –
          Node.js/Express.js, MongoDB. Производится валидация входящих данных,
          централизованная обработка и логирование ошибок. Авторизация – с
          использованием JWT-токена.
        </p>
        <p className="about__text">
          Фронтенд собран с использованием React. Задействованя хуки useState,
          useEffect, useContext (контекст пользователя), useRef, useHistory,
          useLocation. Данные между сессиями хранятся в LocalStorage.
          Реализована валидация на фронтенде, роуты защищены.
        </p>
      </div>
    </section>
  );
};

export default About;
